import React from 'react'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import TextSection from 'src/components/TextSection'
import { MdLocationOn } from 'react-icons/md'

const SchoolIntro = ({ page }) => {
	let modules = page?.modules || []

	return (
		<TextSection
        headline={<span>
          {page.title}
        </span>}
        headlineElement='h1'
        headlineSize='h1'
        alignment='left'
        actions={[
          <Button to='https://careers.ascendlearning.org/' external>Teach here</Button>,
          <Button to='/enroll' setTheme='textColor'>Enroll your child</Button>,
          <div>
            {page.phone && (
              <TextLink theme='mainColor' to={'tel:' + page.phone} external><span style={{ color: 'var(--light-text-color)' }}>Tel</span> {page.phone}</TextLink>
            )}
          </div>,
					<div>
						{page?.address?.gBusinessLink && (
							<TextLink theme='mainColor' to={page?.address?.gBusinessLink} external><span style={{ color: 'var(--light-text-color)' }}><MdLocationOn style={{ marginBottom: '-2px' }} /></span> {page?.address?.streetAddress}</TextLink>
						)}
					</div>
        ]}
        isFirstSection
        setTheme='default'
        nextTheme={modules[0]?.theme}
      />
	)
}

export default SchoolIntro
