import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { colors, typography, animations, util } from 'src/styles'
import Link from 'src/components/Link'
import Image from 'src/components/Image'
import { TagButton } from 'src/components/Button'
import { slugify, blocksToText, truncate } from 'src/utils/format'

const FeaturedImage = styled(Image)`
  overflow: hidden;
  img {
    overflow: hidden;
  }
  &:after {
    content: '';
    display: block;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity ${ animations.mediumSpeed } ease-in-out;
  }
`

const ArticleTitle = styled.h5`
  background-image: linear-gradient(var(--main-color), var(--main-color));
  background-repeat: no-repeat;
  background-size: 0 1px;
  display: inline;
  transition: background-size .4s ease-in-out;
  padding-bottom: 3px;
  background-position: left 100%;
  ${ ({ hasImage }) => hasImage ? `
    color: var(--text-color);
    ${ typography.h3 }
    max-width: 18em;
    margin-top: 0;
  ` : `
    color: var(--text-color);
    ${ typography.h3 }
    max-width: 10em;
  ` }
`

const StyledCard = styled.div`
  text-align: left;
  --text-color: ${ colors.textColor };
  --light-text-color: ${ colors.lightTextColor };
  ${ ({ hasImage }) => hasImage ? '' : `
    display: flex;
    flex-direction: column;
    vertical-align: top;
    --text-color: ${ colors.white };
    --light-text-color: ${ colors.white };
    --hr-color: ${ colors.textColor };
    --bg-color: ${ colors.mainColor };
    --main-color: ${ colors.white };
    background: var(--bg-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 18px 24px 24px 24px;
    ${ util.responsiveStyles('padding', 36, 24, 22, 20) }
    ${ util.responsiveStyles('padding-top', 30, 20, 18, 16) }
    transition: background ${ animations.mediumSpeed } ease-in-out;
  ` }
  &:hover {
    ${ ({ hasImage }) => hasImage ? `
      ${ FeaturedImage } {
        &:after {
          opacity: .15;
        }
      }
      --light-text-color: ${ colors.textColor };
    ` : `
      background: ${ colors.mainColorDarken };
    ` }
    ${ ArticleTitle } {
      background-size: 100% 1px;
    }
  }
`

const PostInfo = styled(Link)`
  flex-grow: 1;
  display: block;
  flex-shrink: 1;
  ${ ({ hasImage }) => hasImage ? `
    padding: 10px 0 18px;
  ` : `
    padding: 0 0 18px;
  ` }
`

const ArticleDescription = styled.p`
  margin: .5em 0 0;
  max-width: 36em;
  color: var(--light-text-color);
  transition: color ${ animations.mediumSpeed } ease-in-out;
  ${ ({ hasImage }) => hasImage ? `
    ${ typography.bodySmall }
  ` : `
    ${ typography.bodySmall }
    color: var(--text-color);
  ` }
`

const Headshot = styled(Image)`
  border-radius: 50%;
  ${ util.responsiveStyles('width', 40, 30, 30, 30) }
  > img {
    border-radius: 50%;
  }
`

const HeadshotPlaceholder = styled.div`
  border-radius: 50%;
  ${ util.responsiveStyles('width', 40, 30, 30, 30) }
  ${ util.responsiveStyles('height', 40, 30, 30, 30) }
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1em;
  padding-top: .1em;
  text-align: center;
  background: var(--hr-color);
  color: var(--light-text-color);
  ${ typography.bodySmall }
  font-weight: ${ typography.bold };
  transition: color ${ animations.mediumSpeed } ease-in-out;
`

const PostAuthor = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 11px;
  margin-bottom: 14px;
  p {
    margin: 0;
    color: var(--light-text-color);
    margin-left: .75em;
    ${ typography.bodySmall }
    transition: color ${ animations.mediumSpeed } ease-in-out;
  }
`

const CardBottom = styled.div`
  display: block;
  flex-shrink: 0;
  ${ ({ hasImage }) => hasImage ? `
    padding: 0 0 20px;
  ` : '' }
`

const PostTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -5px 0;
`

const TagItem = styled(TagButton)`
  margin: 0 5px 5px 0;
  ${ ({ hasImage }) => !hasImage ? `
    border: 1px solid var(--text-color);
    &:not([disabled]):hover {
      background: var(--text-color);
      border-color: var(--text-color);
    }
  ` : '' }
`

const PostCard = ({ post, className = '' }) => {
  if (!post) {
    return false
  }

  const dateSlug = post.rawPublishedAt.replaceAll('-', '/')
  const hasImage = post?.featuredImage?.asset?.gatsbyImageData
  const postLink = '/' + dateSlug + '/' + post?.slug?.current

  return (
    <StyledCard hasImage={hasImage} className={'post-card ' + className}>
      {hasImage && (
        <Link
          to={postLink}
          css={css`display: block;`}
          title={'Read ' + post.title}
        >
          <FeaturedImage sizes='500px' ratio={9 / 16} image={post?.featuredImage?.asset?.gatsbyImageData} />
        </Link>
      )}
      <PostInfo
        hasImage={hasImage}
        to={postLink}
        title={'Read ' + post.title}
      >
        <div>
          <ArticleTitle hasImage={hasImage}>{post.title}</ArticleTitle>
          {post.author && (
            <PostAuthor>
              {post?.author?.headshot?.asset?.gatsbyImageData ? (
                <Headshot sizes='80px' ratio={1} image={post.author.headshot.asset.gatsbyImageData} />
              ) : (
                <HeadshotPlaceholder>{post.author.name[0]}</HeadshotPlaceholder>
              )}
              <p>by {post.author.name}</p>
            </PostAuthor>
          )}
          {post?._rawBody && (
            <ArticleDescription hasImage={hasImage}>
              {truncate(blocksToText(post._rawBody), 30, '...')}
            </ArticleDescription>
          )}
        </div>
      </PostInfo>

      {post?.categories?.length > 0 && (
        <CardBottom hasImage={hasImage}>
          <PostTags>
            {post?.categories?.map((cat, index) => (
              <TagItem
                to={'/blog/categories/' + slugify(cat?.title)}
                setTheme={hasImage ? 'lightGrey' : 'currentcolorOutlined' }
                hasImage={hasImage}
              >
                {cat?.title}
              </TagItem>
            ))}
          </PostTags>
        </CardBottom>
      )}
    </StyledCard>
  )
}

export default PostCard
