import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid, { Container } from 'src/components/Grid'
import AnimateHeight from 'react-animate-height'
import { MdAdd } from 'react-icons/md'
import { typography, animations } from 'src/styles'

const Wrapper = styled(Section)``

const ItemTitle = styled.button`
	appearance: none;
	outline: none;
	border: none;
	background: transparent;
	width: 100%;
	display: block;
	text-align: left;
	padding: 0;
	margin: 0;
	line-height: 1.2em;
	cursor: pointer;
	display: flex;
	transition: color ${ animations.mediumSpeed } ease-in-out;
	${ ({ small }) => small ? `
		justify-content: flex-start;
		${ typography.body }
		padding-bottom: .375em;
		> svg {
			margin-left: -4px;
			margin-top: 2px;
			margin-right: 10px;
		}
	` : `
		justify-content: space-between;
		${ typography.h3 }
		padding-bottom: .75em;
		> svg {
			margin-top: 4px;
			margin-left: 20px;
		}
	` }
	&:hover {
		color: var(--main-color);
	}
	${ ({ expanded }) => expanded ? `
		color: var(--main-color);
	` : '' }
	${ ({ firstItem, small }) => !firstItem ? `
		${ small ? `
			padding-top: .4em;
		` : `
			border-top: 1px solid var(--hr-color);
			padding-top: .65em;
		` }
	` : '' }
`

const PlusIcon = styled(MdAdd)`
	color: var(--light-text-color);
	transform-style: preserve-3d;
	flex-shrink: 0;
	flex-grow: 0;
	transition: transform ${ animations.mediumSpeed } ease-in-out;
	${ ({ expanded }) => expanded ? `
		transform: rotate(-135deg);
	` : '' }
`

const AccordionSection = ({
	className,
	theme,
	prevTheme,
	nextTheme,
	isFirstSection,
	headline,
	_rawText,
	size = 'large',
	items,
	id
}) => {
	const [expandedItem, setExpandedItem] = useState()
	const toggleExpandedItem = id => {
		if (expandedItem === id) {
			setExpandedItem(false)
		} else {
			setExpandedItem(id)
		}
	}
	return (
		<Wrapper
			className={className}
			setTheme={theme}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			isFirstSection={isFirstSection}
			id={id}
		>
			<Container>
				<Grid
					small='[1]'
					large='[5] 1 [6]'
					extraLarge='[5] 1 [5] 1'
					colGap={['2vw', '3vw', '3vw']}
					vAlign='top'
					rowGap={_rawText ? '20px' : 0}
				>
					<div>{headline && (
						<TextLockup
							headline={headline}
							text={_rawText}
							textSize='body'
							showHr
							theme={theme}
							headlineSize='h1'
						/>
					)}</div>
					<div>
						{(items && items.length > 0) && items.map((item, index) => (
							<div onClick={() => toggleExpandedItem(item._key)}>
								{size === 'small' ? (
									<ItemTitle
										firstItem={index === 0}
										height={expandedItem === item._key ? 'auto' : 0}
										expanded={expandedItem === item._key}
										small={true}
									>
										<PlusIcon size={18} expanded={expandedItem === item._key} />
										{item.itemTitle}
									</ItemTitle>
								) : (
									<ItemTitle
										firstItem={index === 0}
										height={expandedItem === item._key ? 'auto' : 0}
										expanded={expandedItem === item._key}
									>
										{item.itemTitle}
										<PlusIcon size={24} expanded={expandedItem === item._key} />
									</ItemTitle>
								)}
								<AnimateHeight
									duration={300}
									delay={0}
									animateOpacity={size === 'small'}
									height={expandedItem === item._key ? 'auto' : 0}
								>
									<div css={css`
										${ size === 'small' ? `
											padding-bottom: 20px;
											padding-left: 23px;
										` : `
											padding-bottom: 30px;
										` }
									`}>
										<TextLockup
											text={item._rawItemText}
											theme={theme}
											transitionIn={false}
										/>
									</div>
								</AnimateHeight>
							</div>
						))}
					</div>
				</Grid>
			</Container>
		</Wrapper>
	)
}

export default AccordionSection
