import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Section from 'src/components/Section'
import Link from 'src/components/Link'
import Button from 'src/components/Button'
import Grid, { Container } from 'src/components/Grid'
import { slugify } from 'src/utils/format'
import { typography, colors, mq, animations } from 'src/styles'

const Wrapper = styled(Section)``

const SchoolItem = styled(Link)`
	display: block;
	${ typography.bodySmall }
	${ ({ firstItem }) => firstItem ? `
		border-top: 1px solid var(--text-color);
	` : `
		border-top: 1px solid var(--hr-color);
	` }
	padding: 12px 0 10px;
	.title-col {
		position: relative;
		transition: padding ${ animations.mediumSpeed } ease-in-out;
		.button {
			position: absolute;
			right: 0;
			top: 0;
			${ mq.largerAndUp } {
				display: none;
			}
		}
	}
	&:hover {
		${ mq.largerAndUp } {
			background: ${ colors.lightGrey };
			.title-col {
				padding-left: 12px;
			}
		}
	}
`

const SchoolStats = ({
	className,
	theme,
	prevTheme,
	nextTheme,
	isFirstSection,
	id
}) => {
	const { upperSchools, middleSchools, lowerSchools } = useStaticQuery(
		graphql`
			query {
				upperSchools: allSanityUpperSchool {
					edges {
						node {
							_id
							school {
								...School
							}
						}
					}
				}
				middleSchools: allSanityMiddleSchool {
					edges {
						node {
							_id
							school {
								...School
							}
						}
					}
				}
				lowerSchools: allSanityLowerSchool {
					edges {
						node {
							_id
							school {
								...School
							}
						}
					}
				}
			}
		`
	)

	const SchoolLine = ({ school, linkPrefix, firstItem }) => {
		let schoolSlug = slugify(school.title)
		if (school?.slug?.current) {
			schoolSlug = school.slug.current
		}
		return (
			<SchoolItem
				to={'/' + linkPrefix + '/' + schoolSlug}
				firstItem={firstItem}
				title={'View ' + school.title}
			>
				<Grid small='[1]' large='[5] [7]' rowGap='6px'>
					<div className='title-col'>
						<strong>{school.title}</strong> <span style={{ color: colors.green, fontWeight: typography.medium }}>{school.stats.openingYear}</span><br/>
						<span style={{ color: colors.lightTextColor }}>Grades {school.startGrade}–{school.endGrade}</span>
						<Button as='span' size='tiny' shape='circle' icon='keyboard_arrow_right' setTheme='lightGrey' />
					</div>
					<Grid small='[4] [3]' medium='[1] [1]' large='[4] [3]'>
						<div>
							<span style={{ color: colors.blue, fontWeight: typography.medium }}>{school.stats.freeLunch}%</span> free or reduced<span css={css`${ mq.mediumAndBelow } { display: none; }`}>-price</span> lunch<br/>
							<span style={{ color: colors.blue, fontWeight: typography.medium }}>{school.stats.studentsOfColor}%</span> students of color
						</div>
						<Grid small='[1] [1]'>
							<div css={css`
								line-height: 1.2em;
								color: ${ colors.lightTextColor };
								${ mq.largerAndUp } {
									text-align: center;
								}
							`}>
								CSD
								<p className='medium' style={{ lineHeight: '1.2em', fontWeight: typography.medium, margin: 0, color: colors.textColor }}>{school.stats.csd}</p>
							</div>
							<div css={css`
								line-height: 1.2em;
								color: ${ colors.lightTextColor };
								${ mq.largerAndUp } {
									text-align: center;
								}
							`}>
								<p className='medium' style={{ lineHeight: '1.2em', fontWeight: typography.medium, margin: 0, color: colors.orange }}>{school.stats.studentCount}</p>
								students
							</div>
						</Grid>
					</Grid>
				</Grid>
			</SchoolItem>
		)
	}

	return (
		<Wrapper
			className={className}
			setTheme={theme}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			isFirstSection={isFirstSection}
			id={id}
		>
			<Container>
				{lowerSchools?.edges?.length > 0 && (
					<div>
						<h3>Lower Schools</h3>
						{lowerSchools.edges.map((schoolItem, index) => {
							const school = schoolItem?.node?.school
							return (
								<SchoolLine
									key={schoolItem?.node?._id}
									firstItem={index === 0}
									school={school}
									linkPrefix='lower-schools'
								/>
							)
						})}
					</div>
				)}

				{middleSchools?.edges?.length > 0 && (
					<div>
						<h3 style={{ marginTop: '1em' }}>Middle Schools</h3>
						{middleSchools.edges.map((schoolItem, index) => {
							const school = schoolItem?.node?.school
							return (
								<SchoolLine
									key={schoolItem?.node?._id}
									firstItem={index === 0}
									school={school}
									linkPrefix='middle-schools'
								/>
							)
						})}
					</div>
				)}

				{upperSchools?.edges?.length > 0 && (
					<div>
						<h3 style={{ marginTop: '1em' }}>Upper Schools</h3>
						{upperSchools.edges.map((schoolItem, index) => {
							const school = schoolItem?.node?.school
							return (
								<SchoolLine
									key={schoolItem?.node?._id}
									firstItem={index === 0}
									school={school}
									linkPrefix='high-schools'
								/>
							)
						})}
					</div>
				)}
			</Container>
		</Wrapper>
	)
}

export default SchoolStats
