import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import SanityRichText from 'src/components/SanityRichText'
import { Container } from 'src/components/Grid'
import { typography, mq } from 'src/styles'

const Wrapper = styled(Section)`
  text-align: center;
`

const FlexWrapper = styled.div`
  ${ mq.largeAndUp } {
    display: flex;
    align-items: stretch;
  }
`

const ColumnWrapper = styled.div`
  ${ mq.largeAndUp } {
    flex-grow: 1;
    width: 1px;
    ${ ({ position }) => position === 'left' ? `
      padding: 0 40px 0 0;
    ` : `
      padding: 0 0 0 40px;
    ` }
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ColumnItem = styled.div`
  .gatsby-image-wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
`

const CenterText = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  border-left: 1px solid var(--hr-color);
  border-right: 1px solid var(--hr-color);
  ${ typography.h2 }
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  padding: 1em;
  ${ mq.mediumAndBelow } {
    border: none;
    padding: 1.7em 1em 2em;
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      display: block;
      vertical-align: top;
      width: 1px;
      height: 1em;
      background: var(--hr-color);
    }
    &:before {
      top: .6em;
    }
    &:after {
      bottom: .5em;
    }
  }
`

const Column = ({ column }) => {
  if (column.type === 'text') {
    return (
      <ColumnItem>
        <SanityRichText
          text={column._rawText}
          css={css`
            p {
              ${ typography.h2 }
              max-width: 15em;
              margin: 0 auto;
            }
          `}
        />
      </ColumnItem>
    )
  } else if (column.type === 'image' && column?.image?.asset?.gatsbyImageData) {
    return (
      <ColumnItem>
        <h3 css={css`margin: 0;`}>{column?.image?.title}</h3>
        <p css={css`color: var(--light-text-color); margin: .5em 0 0 0;`}>{column?.image?.subtitle}</p>
        <Image css={css`margin-top: 1.85em;`} image={column.image.asset.gatsbyImageData}/>
        <figcaption css={css`max-width: 38em; margin: 1.75em auto 0;`}>{column?.image?.caption}</figcaption>
      </ColumnItem>
    )
  } else {
    return <span/>
  }
}

const Juxtaposition = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  leftColumn,
  rightColumn,
  centerText,
  isFirstSection,
  id
}) => {
  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      id={id}
    >
      <Container>
        <FlexWrapper>
          <ColumnWrapper position='left'>
            <Column column={leftColumn} />
          </ColumnWrapper>
          <CenterText>{centerText || 'and yet...'}</CenterText>
          <ColumnWrapper position='right'>
            <Column column={rightColumn} />
          </ColumnWrapper>
        </FlexWrapper>
      </Container>
    </Wrapper>
  )
}

export default Juxtaposition
