import React, { useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { rgba } from 'polished'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import Link from 'src/components/Link'
import PostCard from 'src/components/PostCard'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import Slider from 'react-slick'
import { MdArrowForward } from 'react-icons/md'
import { mq, globals, animations, typography, colors } from 'src/styles'

const Wrapper = styled(Section)`
  --rowGap: 40px;
`

const LinkCard = styled(Link)`
  --text-color: var(--text-color);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  background: ${ rgba(colors.textColor, 0.07) };
  ${ typography.h2 }
  transition: color ${ animations.mediumSpeed } ease-in-out, background ${ animations.mediumSpeed } ease-in-out;
  &:hover {
    background: ${ rgba(colors.textColor, 0.15) };
  }
  .icon {
    background: var(--bg-color);
    color: var(--main-color);
    border-radius: 50%;
    display: block;
    padding: .3em;
    margin-top: .5em;
    ${ typography.h3 }
    svg {
      display: block;
    }
  }
`

const Slide = styled.div`
  padding: 0 var(--rowGap) 0 0;
  height: 100%;
  ${ mq.mediumAndBelow } {
    padding: 0 0 0 var(--site-margins);
  }
  > .post-card,
  > .link-card {
    width: calc((100vw - var(--rowGap) * 3 - (100vw / 7)) / 4);
    ${ mq.largerAndBelow } {
      width: calc((100vw - var(--rowGap) * 2 - (100vw / 7)) / 3);
    }
    ${ mq.largeAndBelow } {
      width: 41.5vw;
    }
    ${ mq.mediumAndBelow } {
      width: 60vw;
    }
    ${ mq.smallAndBelow } {
      width: 80vw;
    }
  }
  ${ ({ firstSlide }) => firstSlide ? `
    padding-left: var(--site-margins);
  ` : '' }
  ${ ({ lastSlide }) => lastSlide ? `
    padding-right: var(--site-margins);
    ${ mq.mediumAndUp } {
      padding-right: ${ 100 / 14 }vw;
    }
    ${ mq.largerAndUp } {
      padding-right: calc(${ 100 / 14 }vw + 15px);
    }
  ` : '' }
`

const PostSlider = styled(Slider)`
  width: 100%;
  max-width: 100%;
  .slick-track {
    display: flex;
    align-items: stretch;
    .slick-slide {
      float: none !important;
      height: auto;
      flex-shrink: 0;
      flex-grow: 0;
      > div {
        height: 100%;
        .post-card {
          height: 100%;
        }
      }
    }
  }
  > div {
    > a {
      height: 100%;
    }
  }
`

const SectionHeader = styled.div`
  margin-top: -20px;
  ${ globals.verticalSpacing('padding-bottom', 0.3) }
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SliderControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  margin: 0 -16px 20px;
  ${ mq.largerAndUp } {
    margin: 0 -20px;
  }
  ${ ({ postCount }) => `
    ${ postCount > 4 ? 'display: block;' : 'display: none;' }
    ${ mq.largerAndBelow } {
      ${ postCount > 3 ? 'display: block;' : 'display: none;' }
    }
    ${ mq.largeAndBelow } {
      ${ postCount > 2 ? 'display: block;' : 'display: none;' }
    }
    ${ mq.mediumAndBelow } {
      ${ postCount > 2 ? 'display: block;' : 'display: none;' }
    }
    ${ mq.smallAndBelow } {
      ${ postCount > 1 ? 'display: block;' : 'display: none;' }
    }
  ` }
  > button {
    background: transparent;
    border-color: transparent;
    color: var(--text-color);
  }
  > button:first-of-type {
    margin-right: 10px;
  }
`

const ArticleSlider = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  isFirstSection,
  headline,
  listingType,
  posts,
  order,
  id
}) => {
  const {
    recentPosts,
    allPosts,
    pressPosts
  } = useStaticQuery(
    graphql`
      query {
        recentPosts: allSanityPost(
          sort: {fields: publishedAt, order: DESC}
          filter: {hidden: {ne: true}}
          limit: 10
        ) {
          edges {
            node {
              ...Post
            }
          }
        }
        allPosts: allSanityPost(
          sort: {fields: publishedAt, order: DESC}
          filter: {hidden: {ne: true}}
        ) {
          edges {
            node {
              ...Post
            }
          }
        }
        pressPosts: allSanityPost(
          sort: {fields: publishedAt, order: DESC}
          filter: {categories: {elemMatch: {title: {eq: "Press"}}}, hidden: {ne: true}}
        ) {
          edges {
            node {
              ...Post
            }
          }
        }
      }
    `
  )

  const randomOrder = (a, b, c, d) => {
   c=a.length;while(c)b=Math.random()*c--|0,d=a[c],a[c]=a[b],a[b]=d
  }

  let randomPosts = allPosts.edges
  useEffect(() => {
    randomPosts = randomOrder(allPosts.edges)
  }, [])

  if (listingType === 'press') {
    posts = pressPosts?.edges?.slice(0, 10)
  } else if (order === 'random') {
    posts = randomPosts.slice(0, 10)
  } else if (listingType === 'most-recent') {
    posts = recentPosts?.edges
  }

  const slider = useRef(null)
  const settings = {
    arrows: false,
    slidesToShow: posts.length <= 4 ? posts.length : 4,
    infinite: false,
    variableWidth: true,
    centerMode: false,
    swipe: true,
    speed: 500,
    responsive: [
      {
        breakpoint: mq.largerBreakpoint,
        settings: {
          slidesToShow: posts.length <= 3 ? posts.length : 3,
        }
      },
      {
        breakpoint: mq.largeBreakpoint,
        settings: {
          slidesToShow: posts.length <= 2 ? posts.length : 2,
        }
      },
      {
        breakpoint: mq.mediumBreakpoint,
        settings: {
          slidesToShow: posts.length <= 2 ? posts.length : 2,
        }
      },
      {
        breakpoint: mq.smallBreakpoint,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }

  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      id={id}
      fullWidth
    >
      <Grid small='m [1] m'>
        <SectionHeader>
          <h3 className='h2'>{headline || 'From the blog'}</h3>
          <ResponsiveComponent small={<span/>} large={
            <SliderControls postCount={posts?.length}>
              <Button
                icon='arrow_back'
                onClick={() => slider?.current?.slickPrev()}
                shape='circle'
                title='Go to previous slide'
                setTheme='white'
              />
              <Button
                icon='arrow_forward'
                onClick={() => slider?.current?.slickNext()}
                shape='circle'
                title='Go to next slide'
                setTheme='white'
              />
            </SliderControls>
          }/>
        </SectionHeader>
      </Grid>
      <PostSlider ref={slider} {...settings}>
        {posts.map((post, index) => (
          <Slide
            key={post._key}
            firstSlide={index === 0}
          >
            <PostCard post={post?.node || post} />
          </Slide>
        ))}
        <Slide lastSlide={true}>
          <LinkCard
            className='link-card'
            to='/blog'
          >
            All articles
            <div className='icon'>
              <MdArrowForward />
            </div>
          </LinkCard>
        </Slide>
      </PostSlider>
      <ResponsiveComponent large={<span/>} small={
        <Grid small='container'>
          <SliderControls postCount={posts?.length}>
            <Button
              icon='arrow_back'
              onClick={() => slider?.current?.slickPrev()}
              shape='circle'
              title='Go to previous slide'
              setTheme='white'
            />
            <Button
              icon='arrow_forward'
              onClick={() => slider?.current?.slickNext()}
              shape='circle'
              title='Go to next slide'
              setTheme='white'
            />
          </SliderControls>
        </Grid>
      }/>
    </Wrapper>
  )
}

export default ArticleSlider
