import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import SEO from 'src/components/SEO'
import Header, { headerHeightCollapsed } from 'src/components/Header'
import Footer from 'src/components/Footer'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import Grid from 'src/components/Grid'
import SanityRichText from 'src/components/SanityRichText'
import ArticleSlider from 'src/components/ArticleSlider'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Button, { TagButton } from 'src/components/Button'
import { typography, colors, util, mq } from 'src/styles'
import { FaFacebook, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { slugify } from 'src/utils/format'

const AuthorImage = styled(Image)`
  border-radius: 50%;
  ${ util.responsiveStyles('width', 50, 40, 40, 40) }
  ${ util.responsiveStyles('margin-right', 16, 14, 13, 12) }
  > img {
    border-radius: 50%;
  }
`

const BlogContent = styled(SanityRichText)`
  blockquote {
    background: ${ colors.lightGrey };
    border-radius: var(--card-border-radius);
    color: ${ colors.lightTextColor };
    ${ typography.body }
    ${ util.responsiveStyles('padding', 40, 32, 26, 20) }
    margin: 2em 0;
  }
  ul {
    li {
      &:before {
        background: ${ colors.textColor };
        opacity: 0.5;
      }
    }
  }
`

const PostTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -5px 0;
  ${ util.responsiveStyles('margin-top', 60, 50, 40, 30) }
  h6 {
    width: 100%;
    margin: 0 0 .75em;
    color: var(--light-text-color);
  }
  ${ mq.largeAndBelow } {
    text-align: center;
    justify-content: center;
  }
`

const TagItem = styled(TagButton)`
  margin: 0 5px 5px 0;
`

const ShareWrapper = styled.div`
  text-align: center;
  ${ mq.largeAndBelow } {
    ${ util.responsiveStyles('margin-top', 60, 50, 40, 30) }
  }
  ${ mq.largerAndUp } {
    position: absolute;
    top: -30px;
    bottom: 80px;
    left: calc(100% + 20px);
    > div {
      position: sticky;
      padding-top: 30px;
      ${ headerHeightCollapsed('top') }
    }
  }
  .share-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      color: ${ colors.textColor };
      &:hover {
        background: transparent;
        border-color: ${ colors.mainColor };
        color: ${ colors.mainColor };
      }
    }
    ${ mq.largerAndUp } {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
  h6 {
    font-size: 12px;
    font-family: ${ typography.primaryFont };
    font-weight: ${ typography.bold };
    color: ${ colors.lightTextColor };
    text-transform: uppercase;
    letter-spacing: .1em;
    margin-bottom: 1em;
  }
`

const Post = ({ data }) => {
  const post = data?.sanityPost
  const dateSlug = post?.rawPublishedAt?.replaceAll('-', '/')
  const path = dateSlug + '/' + post?.slug?.current

  // Sharing Setup
  const shareUrl = process.env.GATSBY_SITE_URL + '/' + dateSlug + '/' + post?.slug?.current
  const shareDescription = ''
  const shareImage = post?.featuredImage?.asset?.url + '?w=800'

  const sharePost = (url, width = 600, height = 700) => {
    if (window) {
      window?.open(url, 'targetWindow',
                   `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=${ width },
                    height=${ height }`)
    }
  }

  return (
    <>
      <SEO
        pagePath={path}
        title={post.title}
        description={post?.description}
        shareImage={shareImage}
      />
      <Header
        hasAtf={false}
        location={path}
      />
      <Section
        setTheme='default'
        prevTheme={false}
        nextTheme='lightGrey'
        isFirstSection={true}
        as={ScrollEntrance}
      >
        <Grid
          small='container'
          large='[1]'
          css={css`
            ${ util.responsiveStyles('margin-bottom', 40, 40, 30, 26) }
            ${ util.responsiveStyles('max-width', 900, 800, 750, 700) }
            margin-left: auto;
            margin-right: auto;
          `}
        >
          <div>
            <h1
              className='h1'
              css={css`
                text-align: left;
                max-width: 16em;
                margin: 0 0 20px 0;
              `}
            >{post.title}</h1>
            <div css={css`
              display: flex;
              align-items: center;
              justify-content: flex-start;
              color: ${ colors.lightTextColor };
              flex-wrap: wrap;
            `}>
              <div css={css`
                display: flex;
                align-items: center;
                justify-content: flex-start;
              `}>
                {post?.author?.headshot?.asset?.gatsbyImageData && (
                  <AuthorImage ratio={1} image={post.author.headshot.asset.gatsbyImageData} />
                )}
                {post.author && (<p className='small'>{post.author.name}</p>)}
              </div>
              {post.author && post.publishedAt && (
                <hr css={css`
                  height: 6px;
                  width: 6px;
                  border-radius: 50%;
                  border: none;
                  background: ${ colors.hrColor };
                  margin: 0 14px;
                `}/>
              )}
              <p className='small'>{post.publishedAt}</p>
            </div>
          </div>
        </Grid>
        <Grid
          small='container'
          large='[1]'
          css={css`
            ${ util.responsiveStyles('max-width', 900, 800, 750, 700) }
            margin-left: auto;
            margin-right: auto;
            position: relative;
          `}
        >
          <div>
            {post?.featuredImage?.asset?.gatsbyImageData && (
              <div style={{ marginBottom: '20px' }}>
                <Image image={post.featuredImage.asset.gatsbyImageData} rounded />
              </div>
            )}

            <BlogContent text={post._rawBody}/>

            <ShareWrapper>
              <div>
                <h6>Share</h6>
                <div className='share-buttons'>
                  <Button
                    setTheme='transparent'
                    icon={<FaFacebook size={22} />}
                    shape='square'
                    onClick={() => sharePost('https://www.facebook.com/sharer/sharer.php?u=' + shareUrl)}
                  />
                  <Button
                    setTheme='transparent'
                    icon={<FaTwitter size={20} />}
                    shape='square'
                    onClick={() => sharePost('https://twitter.com/intent/tweet?url=' + shareUrl + '&text=' + shareDescription)}
                  />
                  <Button
                    setTheme='transparent'
                    icon={<FaLinkedinIn size={20} />}
                    shape='square'
                    onClick={() => sharePost('https://www.linkedin.com/sharing/share-offsite/?url=' + shareUrl)}
                  />
                </div>
              </div>
            </ShareWrapper>

            {post?.categories?.length > 0 && (
              <PostTags>
                <h6>Categorized as...</h6>
                {post?.categories?.map((cat, index) => (
                  <TagItem to={'/blog/categories/' + slugify(cat?.title)}>{cat?.title}</TagItem>
                ))}
              </PostTags>
            )}
          </div>
        </Grid>
      </Section>
      <ArticleSlider
        listingType='most-recent'
        order='random'
        headline='Other Articles'
        theme='lightGrey'
        prevTheme='default'
      />
      <Footer />
    </>
  )
}

export const postQuery = graphql`
  query ($id: String!) {
    sanityPost(id: { eq: $id }) {
      ...Post
    }
    blogPage: sanityPage(content: {main: {type: {eq: "blog"}}}) {
      ...Page
    }
  }
`

export default Post
