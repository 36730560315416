import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid, { Container } from 'src/components/Grid'
import Image from 'src/components/Image'
import TextSection from 'src/components/TextSection'
import TextLink from 'src/components/TextLink'
import Link from 'src/components/Link'
import { mq, globals, colors } from 'src/styles'
import { getSanityLink } from 'src/utils/format'

const Wrapper = styled(Section)`
  ${ mq.largeAndBelow } {
    ${ globals.verticalSpacing('padding-bottom') }
  }
  h5 {
    margin-top: 1em;
  }
  p {
    color: ${ colors.lightTextColor };
  }
`

const IntroTextSection = styled(TextSection)`
  ${ globals.verticalSpacing('padding-bottom', 0) }
`

const InstagramLinks = ({
  _key,
  className,
  theme,
  prevTheme,
  nextTheme,
  isFirstSection,
  items
}) => {
  // set responsive image sizes
  // let sizes = '100vw'
  // const imageSize = mediaSizes.default[mediaWidth]
  // sizes = '(min-width: ' + mq.mediumBreakpoint + 'px) ' + imageSize + 'vw, 86vw'
  return (
    <>
      <IntroTextSection
        isFirstSection
        headline='Instagram'
        headlineElement='h1'
        headlineSize='h1'
        setTheme='default'
        nextTheme='default'
        showHr
        alignment='left'
        isFirstSection={isFirstSection}
      />
      <Wrapper
        className={className}
        setTheme='default'
        prevTheme='default'
        nextTheme={nextTheme}
      >
        <Container>
          <Grid
            small='[1] [1]'
            medium='[1] [1]'
            large='[1] [1] [1] [1]'
            // larger='[1] [1] [1] [1] [1]'
            extraLarge='[1] [1] [1] [1] [1] [1]'
            colGap={['20px', '30px', '40px']}
            rowGap={['30px', '50px', '60px']}
            vAlign='top'
          >
            {items.map((item, index) => (
              <Link
                target={item.link.newTab && '_blank'}
                to={getSanityLink(item.link)}
                external={item.link.externalLink}
                title={item.link.title}
                name={item.link.title}
              >
                {item?.asset?.gatsbyImageData && (
                  <Image size='300px' image={item.asset.gatsbyImageData} />
                )}
                <h5>{item.title}</h5>
                <p className='tiny' style={{ margin: '.5em 0' }}>{item.description}</p>
                <TextLink
                  size='bodySmall'
                  theme='mainColor'
                  as='span'
                >{item.link.title}</TextLink>
              </Link>
            ))}
          </Grid>
        </Container>
      </Wrapper>
    </>
  )
}

export default InstagramLinks
