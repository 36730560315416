import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import styled from '@emotion/styled'

import Marker from 'components/Marker'

import { mapStyles, coordinates, centers } from 'utils/map'

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	.gm-style-iw-t {
		display: none;
	}
`

const Map = ({ neighborhood, locations, currentSchool }) => {
	const [map, setMap] = useState(null)
	const [maps, setMaps] = useState(null)
	const [infoBoxVisible, setInfoBoxVisible] = useState(false)

	const defaultProps = {
		center: { lat: 40.675753, lng: -73.902510 },
		zoom: 12.5,
	}

	const mapOptions = { disableDefaultUI: true, ...mapStyles }

	useEffect(() => {
		if (neighborhood && map && maps) {
			const areaPolygon = new maps.Polygon({
				paths: coordinates[neighborhood],
				strokeColor: '#F9CB77',
				strokeOpacity: 0.8,
				strokeWeight: 4,
				fillColor: '#FF0000',
				fillOpacity: 0
			})

			areaPolygon.setMap(map)
			const center = new maps.LatLng(centers[neighborhood].lat, centers[neighborhood].lng)
			map.setCenter(center)
			map.setZoom(14)
		}
		if (currentSchool && map && maps) {
			// console.log(currentSchool)
			// console.log(locations)
			const currentIndex = locations.findIndex(location => location.node._id === currentSchool._id)
			const center = new maps.LatLng(currentSchool.school.mapLocation.lat, currentSchool.school.mapLocation.lng)
			map.setCenter(center)
			map.setZoom(14)
			if (currentIndex !== -1) {
				setInfoBoxVisible(currentIndex)
			}
		}
	}, [neighborhood, map, maps, currentSchool])

	const handleMapLoad = (map, maps) => {
		setMap(map)
		setMaps(maps)
	}

	return (
		<Wrapper className='map-wrapper'>
			<GoogleMapReact
				bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
				defaultCenter={defaultProps.center}
				defaultZoom={defaultProps.zoom}
				options={mapOptions}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => handleMapLoad(map, maps)}
			>
				{locations.map((location, index) => {
					if (!location?.node?.school?.mapLocation.lat || !location?.node?.school?.mapLocation.lng) {
						return false
					}
					return (
						<Marker
							key={index}
							index={index}
							school={location?.node?.school}
							type={location?.node?._type}
							infoBoxVisible={infoBoxVisible}
							setInfoBoxVisible={setInfoBoxVisible}
							lat={location?.node?.school?.mapLocation.lat}
							lng={location?.node?.school?.mapLocation.lng}
						/>
					)
				})}
			</GoogleMapReact>
		</Wrapper>
	)
}

export default Map
