import React, { useRef, useCallback } from 'react'
import styled from '@emotion/styled'

import MapSvg from 'src/assets/images/neighborhood-map.svg'

import { animations } from 'src/styles'

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: block;
	${ ({ hidden }) => hidden ? `opacity: 0;` : `` }
	path {
		&.area-fill,
		&.area-text {
			cursor: pointer;
		}
		&.area-stroke,
		&.all-stroke {
			opacity: 0;
			visibility: hidden;
			transition: visibility ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
			&.map-hover {
				opacity: 1;
				visibility: visible;
			}
		}
	}
`

const NeighborhoodMap = ({ setNeighborhood, hidden }) => {

	const divRef = useRef(null)
	function useRefWithCallback(onMount, onUnmount) {

		const setRef = useCallback(node => {
			if (divRef.current) {
				onUnmount(divRef.current)
			}

			divRef.current = node

			if (divRef.current) {
				onMount(divRef.current)
			}
		}, [onMount, onUnmount])

		return setRef
	}

	const onMouseDown = useCallback(e => {
		const area = e.target.getAttribute('data-area')
		setNeighborhood(area)
	}, [])

	const onMouseEnter = useCallback(e => {
		const area = e.target.getAttribute('data-area')
		const areaStroke = divRef.current.querySelector(`.area-stroke#${area}_stroke`)
		const hoverClass = areaStroke.getAttribute('class').split(' ').includes('map-hover') ? areaStroke.getAttribute('class') : `${areaStroke.getAttribute('class')} map-hover`
		areaStroke.setAttribute('class', hoverClass)
	}, [])

	const onMouseLeave = useCallback(e => {
		const area = e.target.getAttribute('data-area')
		const areaStroke = divRef.current.querySelector(`.area-stroke#${area}_stroke`)
		areaStroke.setAttribute('class', `${areaStroke.getAttribute('class')} map-hover`)
		const unhoverClass = areaStroke.getAttribute('class').split(' ').filter((s) => s !== 'map-hover').join(' ')
		areaStroke.setAttribute('class', unhoverClass)
	}, [])

	const setDivRef = useRefWithCallback(
		node => {
			const areaNodes = node.querySelectorAll('.area-fill, .area-text')
			areaNodes.forEach(node => {
				node.addEventListener("mousedown", onMouseDown)
				node.addEventListener("mouseenter", onMouseEnter)
				node.addEventListener("mouseleave", onMouseLeave)
			})
		},
		node => {
			node.removeEventListener("mousedown", onMouseDown)
			node.removeEventListener("mouseenter", onMouseEnter)
			node.removeEventListener("mouseleave", onMouseLeave)
		}
	)

	return (
		<Wrapper
			ref={setDivRef}
			hidden={hidden}
		><MapSvg /></Wrapper>
	)
}

export default NeighborhoodMap
