import React from 'react'
import { slugify } from 'src/utils/format'
import TextSection from 'src/components/TextSection'
import WideMedia from 'src/components/WideMedia'
import FiftyFifty from 'src/components/FiftyFifty'
import Columns from 'src/components/Columns'
import TwoColumnText from 'src/components/TwoColumnText'
import TeamGrid from 'src/components/TeamGrid'
import Reviews from 'src/components/Reviews'
import SchoolStats from 'src/components/SchoolStats'
import ArticleSlider from 'src/components/ArticleSlider'
import SchoolList from 'src/components/SchoolList'
import Slideshow from 'src/components/Slideshow'
import Juxtaposition from 'src/components/Juxtaposition'
import Splitter from 'src/components/Splitter'
// plopImportModules
import AccordionSection from 'src/components/AccordionSection'
import Curriculum from 'src/components/Curriculum'
import SchoolsMap from 'src/components/SchoolsMap'
import Programs from 'src/components/Programs'

const componentMap = {
  textSection: TextSection,
  wideMedia: WideMedia,
  fiftyFifty: FiftyFifty,
  columns: Columns,
  twoColumnText: TwoColumnText,
  teamGrid: TeamGrid,
  blogArticles: ArticleSlider,
  reviews: Reviews,
  schoolStats: SchoolStats,
  schoolList: SchoolList,
  slideshow: Slideshow,
  juxtaposition: Juxtaposition,
  splitter: Splitter,
  // plopAddModules
	accordionSection: AccordionSection,
	curriculum: Curriculum,
  schoolsMap: SchoolsMap,
  programs: Programs,
}

const ComponentRenderer = ({ item, prevTheme, nextTheme, index, isLastSection, isFirstSection }) => {
  if (!item || !item?._type) {
    return false
  }
  const Component = componentMap[item._type]
  if (!Component) {
    // return false
    return (<div><h3>TODO: {item?._type}</h3></div>)
  }
  return Component ? (
    <Component
      {...item}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      id={slugify(item.internalName)}
      isLastSection={isLastSection}
      isFirstSection={isFirstSection}
      index={index}
    />
  ) : null
}

export default ComponentRenderer
