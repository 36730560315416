import React from 'react'
import { graphql } from 'gatsby'
import SEO from 'src/components/SEO'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import ComponentRenderer from 'src/components/ComponentRenderer'
import ArticleGrid from 'src/components/ArticleGrid'
import { getBackupShareImage } from 'src/utils/getBackupShareImage'

const Blog = ({ data }) => {
  const page = data?.sanityPage?.content?.main
  const pageMeta = data?.sanityPage?.content?.meta
  const path = page?.slug?.current
  let modules = page?.modules || []
    // Filter out hidden modules
    modules = modules?.filter(module => !module?.hidden)
  const shareImage = pageMeta?.shareImage?.asset?.url
  // const { blogSettings } = page

  const { recentPosts } = data
  const gridPosts = recentPosts.edges

  return (
    <>
      <SEO
        pagePath={path}
        title={page.title}
        description={pageMeta?.metaDescription}
        keywords={pageMeta?.keywords}
        shareImage={shareImage ? shareImage + '?w=800' : getBackupShareImage(modules)}
      />
      <Header
        hasAtf={true}
        location={path}
      />

      <ArticleGrid
        posts={gridPosts}
        theme='default'
        prevTheme='navy'
        nextTheme={modules[0]?.theme}
        topText={page._rawTopArea}
      />
      {modules?.map((item, index) => {
        const prevSection = modules[index - 1]
        const nextSection = modules[index + 1]
        let prevTheme = false
        let nextTheme = false
        if (prevSection && prevSection.width !== 'fullWidth') {
          prevTheme = prevSection.theme || 'default'
        }
        if (nextSection && nextSection.width !== 'fullWidth') {
          nextTheme = nextSection.theme || 'default'
        }
        if (!item.theme && item.width !== 'fullWidth') {
          item.theme = 'default'
        }
        return (
          <ComponentRenderer
            item={item} key={item?._key || 'section-' + index}
            nextTheme={nextTheme}
            prevTheme={index === 0 ? 'default' : prevTheme}
            isFirstSection={false}
            isLastSection={index === modules.length - 1}
          />
        )
      })}
      {/* RenderModules(modules) */}
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...Page
    }
    recentPosts: allSanityPost(
      sort: {fields: publishedAt, order: DESC}
      filter: {hidden: {ne: true}}
      limit: 100
    ) {
      edges {
        node {
          ...Post
        }
      }
    }
  }
`

export default Blog
