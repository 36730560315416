import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'
import TextLink from 'src/components/TextLink'
import Image from 'src/components/Image'
import { getBackupShareImage } from 'src/utils/getBackupShareImage'
import { colors, animations } from 'src/styles'

const Wrapper = styled(Section)``

const SchoolItem = styled.div`
	.gatsby-image-wrapper {
		&:after {
			background: ${ colors.textColor };
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			content: '';
			display: block;
			opacity: 0;
			transition: opacity ${ animations.mediumSpeed } ease-in-out;
		}
	}
	> a {
		display: block;
		&:hover {
			.text-link {
				&:after {
					transform-origin: left center;
					transform: scaleX(1);
				}
			}
			.gatsby-image-wrapper:after {
				opacity: .2;
			}
		}
	}
`

const Programs = ({
	className,
	theme,
	prevTheme,
	nextTheme,
	isFirstSection,
	id
}) => {
	const {
		lowerSchoolsPage,
		middleSchoolsPage,
		highSchoolsPage
	} = useStaticQuery(
		graphql`
			query {
				lowerSchoolsPage: sanityPage(content: {main: {slug: {current: {eq: "schools/lower-schools"}}}}) {
			    ...Page
			  }
			  middleSchoolsPage: sanityPage(content: {main: {slug: {current: {eq: "schools/middle-schools"}}}}) {
			    ...Page
			  }
			  highSchoolsPage: sanityPage(content: {main: {slug: {current: {eq: "schools/high-schools"}}}}) {
			    ...Page
			  }
			}
		`
	)

	const getPageImage = page => {
		let modules = page?.content?.main?.modules
		modules = modules?.filter(module => !module?.hidden)
		return getBackupShareImage(modules, true)
	}

	return (
		<Wrapper
			className={className}
			setTheme={theme}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			isFirstSection={isFirstSection}
			id={id}
		>
			<Grid small='m [1] m'>
				<div style={{ marginBottom: 'calc(var(--site-margins) / 2)' }}>
					<h3 className='h1'>Our programs</h3>
				</div>
				<div>
					<Grid
						small='[1]'
						medium='[1] [1] [1]'
						colGap='var(--site-gutters)'
						rowGap='var(--site-margins)'
						vAlign='top'
					>
						<SchoolItem>
							<Link to='/schools/lower-schools' title='Learn more about Ascend lower schools'>
								<Image image={getPageImage(lowerSchoolsPage)} sizes='1000px' ratio={1} />
								<h4 className='h3' style={{ marginTop: '.65em' }}>Lower schools</h4>
								<TextLink theme='mainColor' as='span'>Learn more</TextLink>
							</Link>
						</SchoolItem>
						<SchoolItem>
							<Link to='/schools/middle-schools' title='Learn more about Ascend middle schools'>
								<Image image={getPageImage(middleSchoolsPage)} sizes='1000px' ratio={1} />
								<h4 className='h3' style={{ marginTop: '.65em' }}>Middle schools</h4>
								<TextLink theme='mainColor' as='span'>Learn more</TextLink>
							</Link>
						</SchoolItem>
						<SchoolItem>
							<Link to='/schools/high-schools' title='Learn more about Ascend high schools'>
								<Image image={getPageImage(highSchoolsPage)} sizes='1000px' ratio={1} />
								<h4 className='h3' style={{ marginTop: '.65em' }}>High schools</h4>
								<TextLink theme='mainColor' as='span'>Learn more</TextLink>
							</Link>
						</SchoolItem>
					</Grid>
				</div>
			</Grid>
		</Wrapper>
	)
}

export default Programs
