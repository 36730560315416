import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import Image from 'src/components/Image'
import { typography, util, colors, mq } from 'src/styles'
import { getSanityLink } from 'src/utils/format'
import GreenPin from 'assets/images/greenPin.svg'
import OrangePin from 'assets/images/orangePin.svg'
import BluePin from 'assets/images/bluePin.svg'

const Pin = styled.div`
	transition: transform 0.2s ease-in-out;
	transform: scale(1.5);
	transform-origin: bottom center;
	${ ({ activeItem }) => activeItem ? `
		z-index: 10;
	` : '' }
`

const StyledMarker = styled.div`
	position: absolute;
	top: -7px;
	left: -20px;

	${ ({ hover, infoBox }) => (hover && !infoBox) && `${ Pin } {transform: scale(2); }` }
`

const InfoBoxWrapper = styled.div`
	position: relative;
	border: 1px solid red;
	width: 13px;
`

const InfoBox = styled.div`
	position: absolute;
	z-index: 100;
	min-width: 275px;
	left: 0;
	bottom: 20px;
	transform: translate3d(-50%, 0, 0);
	background: ${ colors.bgColor };
	display: flex;
	direction: ltr;
`

const InfoBoxImage = styled.div`
	width: 126px;
	flex-grow: 0;
	flex-shrink: 0;
	${ util.responsiveStyles('width', 130, 120, 114, 114) }
`

const InfoBoxContent = styled.div`
	width: 100%;
	flex-grow: 1;
	flex-shrink: 0;
	padding: 10px 30px 10px 10px;
	&:after {
		content: '';
		display: block;
		position: absolute;
		border-left: 9px solid transparent;
		border-top: 9px solid ${ colors.bgColor };
		border-right: 9px solid transparent;
		bottom: -7px;
		left: 50%;
		margin-left: -5px;
	}
	p {
		${ typography.bodyTiny }
		line-height: 1.4em;
		color: var(--light-text-color);
		margin: 0;
		white-space: nowrap;
		strong {
			color: var(--text-color);
			font-weight: ${ typography.bold };
			display: block;
			margin-bottom: 1px;
		}
		&:last-of-type {
			margin-bottom: 8px;
		}
	}
`

const CloseButton = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
`

const Marker = ({ index, school, infoBoxVisible, setInfoBoxVisible, $hover, type }) => {
	const schoolLink = getSanityLink({link: { _type: type, school: { title: school.title } }})
	const path = location?.pathname
	return (
		<StyledMarker infoBox={infoBoxVisible === index} hover={$hover}>
			<InfoBoxWrapper>
				{infoBoxVisible === index && (
					<InfoBox>
						<CloseButton onClick={() => setInfoBoxVisible(false)} icon='close' shape='circle' size='tiny' setTheme='transparent'/>
						<InfoBoxImage>
							<Image image={school.thumbnailSm.asset.gatsbyImageData} ratio={1}/>
						</InfoBoxImage>
						<InfoBoxContent>
							<p><strong>{school.title}</strong></p>
							<p>
								{school.address.streetAddress}<br/>
								{school.address.city}, {school.address.state} {school.address.zip}
							</p>
							{path !== '/enroll' ? (
								<>
									<Button css={css`margin-right: 10px;`} to='/enroll' size='tiny'>Enroll Now</Button>
									<TextLink to={schoolLink} size='bodyTiny'>Details</TextLink>
								</>
							) : (
								<>
									<Button to={schoolLink} size='tiny'>Details</Button>
								</>
							)}
						</InfoBoxContent>
					</InfoBox>
				)}
			</InfoBoxWrapper>
			<Pin onClick={() => setInfoBoxVisible(index)} activeItem={infoBoxVisible}>
				{/* high_schools */}
				{type === 'upperSchool' && (<BluePin />)}
				{/* middle_schools */}
				{type === 'middleSchool' && (<GreenPin />)}
				{/* lower_schools */}
				{(type === 'lowerSchool' || !type) && (<OrangePin />)}
			</Pin>
		</StyledMarker>
	)
}

export const MapPin = ({ type }) => {
	if (type === 'upperSchool') {
		return (<BluePin />)
	} else if (type === 'middleSchool') {
		return (<GreenPin />)
	} else {
		return (<OrangePin />)
	}
}

export default Marker
