import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { rgba } from 'polished'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Link from 'src/components/Link'
import TextLink from 'src/components/TextLink'
import Image from 'src/components/Image'
import TextLockup from 'src/components/TextLockup'
import { headerHeightCollapsed } from 'src/components/Header'
import Grid, { Container } from 'src/components/Grid'
import { slugify } from 'src/utils/format'
import { typography, util, mq, colors, animations } from 'src/styles'

const Wrapper = styled(Section)`
`

const TextCol = styled.div`
	${ mq.largerAndUp } {
		height: 100%;
		> div {
			position: sticky;
			padding-top: 40px;
			margin-top: -40px;
			${ headerHeightCollapsed('top') }
		}
	}
`

const SchoolItem = styled(Link)`
	display: flex;
	align-items: flex-start;
	position: relative;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: -10px;
		right: -10px;
		bottom: -10px;
		left: -10px;
		background: ${ rgba(colors.textColor, 0.05) };
		z-index: -1;
		opacity: 0;
		transition: opacity ${ animations.mediumSpeed } ease-in-out;
	}
	${ ({ firstItem }) => !firstItem ? `
		${ util.responsiveStyles('margin-top', 36, 32, 24, 20) }
	` : '' }
	&:hover {
		&:after {
			opacity: 1;
		}
		.button {
			&:after {
				transform-origin: left center;
				transform: scaleX(1);
			}
		}
	}
`

const ImageWrapper = styled.div`
	${ util.responsiveStyles('width', 166, 140, 130, 120) }
	${ util.responsiveStyles('margin-right', 24, 20, 16, 14) }
`

const SchoolItemInfo = styled.div`
	p {
		margin: 0;
		color: var(--light-text-color);
		${ typography.bodySmall }
		&.h4 {
			color: var(--text-color);
			margin-bottom: .1em;
			${ typography.h4 }
			margin-top: -.2em;
		}
	}
	.button {
		margin-top: .4em;
	}
`

const SchoolList = ({
	className,
	theme,
	prevTheme,
	nextTheme,
	listType = 'lower',
	actions,
	headline,
	description,
	isFirstSection,
	excludedSchool,
	id
}) => {
	const { allSanityLowerSchool, allSanityMiddleSchool, allSanityUpperSchool } = useStaticQuery(
		graphql`
			query {
				allSanityLowerSchool(sort: {order: ASC, fields: school___title}) {
					edges {
						node {
							_id
							school {
								...School
							}
						}
					}
				}
				allSanityMiddleSchool(sort: {order: ASC, fields: school___title}) {
					edges {
						node {
							_id
							school {
								...School
							}
						}
					}
				}
				allSanityUpperSchool(sort: {order: ASC, fields: school___title}) {
					edges {
						node {
							_id
							school {
								...School
							}
						}
					}
				}
			}
		`
	)

	let schools = allSanityLowerSchool.edges

	if (listType === 'upper') {
		schools = allSanityUpperSchool.edges
	}

	if (listType === 'middle') {
		schools = allSanityMiddleSchool.edges
	}

	if (excludedSchool) {
		schools = schools.filter(school => school?.node?._id !== excludedSchool?._id)
	}

	const defaultHeadline = {
		lower: {
			headline: 'Lower School Locations',
			linkPrefix: 'lower-schools'
		},
		middle: {
			headline: 'Middle School Locations',
			linkPrefix: 'middle-schools'
		},
		upper: {
			headline: 'High School Locations',
			linkPrefix: 'high-schools'
		}
	}

	if (schools.length < 1) {
		return false
	}

	return (
		<Wrapper
			className={className}
			setTheme={theme}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			isFirstSection={isFirstSection}
			id={id}
		>
			<Container>
				<Grid small='[1]' large='[11] 1 g [12]' vAlign='top' rowGap='30px'>
					<TextCol>
						<TextLockup
							headline={headline || defaultHeadline[listType].headline}
							headlineSize='h1'
							text={description}
							actions={actions}
							showHr
						/>
					</TextCol>
					<div>
						{schools.map((school, index) => {
							school = school.node
							const schoolItem = school.school
							let schoolSlug = slugify(schoolItem.title)
							if (schoolItem?.slug?.current) {
								schoolSlug = schoolItem?.slug?.current
							}
							return (
								<SchoolItem
									to={'/' + defaultHeadline[listType].linkPrefix + '/' + schoolSlug}
									key={school._id}
									firstItem={index === 0}
									title={'View ' + schoolItem.title}
								>
									<ImageWrapper>
										<Image ratio={2 / 3} image={schoolItem?.thumbnailSm?.asset?.gatsbyImageData} />
									</ImageWrapper>
									<SchoolItemInfo>
										<p className='h4'>{schoolItem.title}</p>
										<p>{schoolItem.address.streetAddress}</p>
										<p>Grades {schoolItem.startGrade}–{schoolItem.endGrade}</p>
										<TextLink theme='mainColor' size='bodySmall' as='span'>Learn More</TextLink>
									</SchoolItemInfo>
								</SchoolItem>
							)
						})}
					</div>
				</Grid>
			</Container>
		</Wrapper>
	)
}

export default SchoolList
