import React from 'react'
import { graphql } from 'gatsby'
// import { RenderModules } from 'src/utils/renderModules'
import SEO from 'src/components/SEO'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import ArticleGrid from 'src/components/ArticleGrid'
import Splitter from 'src/components/Splitter'
import { slugify } from 'src/utils/format'

const Category = ({ data }) => {
  const page = data?.sanityCategory
  const path = 'blog/categories/' + slugify(page.title)

  const { categoryPosts } = data
  const gridPosts = categoryPosts.edges
  const pressPage = page.title === 'Press'

  return (
    <>
      <SEO
        pagePath={path}
        title={page.title}
        // description={pageMeta?.metaDescription}
        // keywords={pageMeta?.keywords}
      />
      <Header location={path} hasAtf={!pressPage} />

      <ArticleGrid
        prevTheme='default'
        posts={gridPosts}
        ctaBar={false}
        topHeadline={!pressPage ? '“' + page.title + '” Articles' : <>Ascend in the <span className='blue'>media</span></>}
        topText={page.description}
        currentSlug={slugify(page.title)}
        isFirstSection
        gridLarger='[1] [1] [1]'
        pressPage={pressPage}
      />

      <Splitter
        prevTheme='default'
        theme='lightGrey'
        nextTheme={false}
        layout='columns'
      />
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    sanityCategory(id: { eq: $id }) {
      _key
      title
      description
    }
    categoryPosts: allSanityPost(
      sort: {fields: publishedAt, order: DESC}
      filter: {categories: {elemMatch: {id: {eq: $id}}}}
    ) {
      edges {
        node {
          ...Post
        }
      }
    }
    blogPage: sanityPage(content: {main: {type: {eq: "blog"}}}) {
      ...Page
    }
  }
`

export default Category
