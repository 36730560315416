
export const mapStyles = {
	styles: [
					{
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#021946"
							}
						]
					},
					{
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#869cc6"
							}
						]
					},
					{
						"elementType": "labels.text.stroke",
						"stylers": [
							{
								"color": "#021946"
							}
						]
					},
					{
						"featureType": "administrative.country",
						"elementType": "geometry.stroke",
						"stylers": [
							{
								"color": "#34476b"
							}
						]
					},
					{
						"featureType": "administrative.land_parcel",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "administrative.land_parcel",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#7d9dde"
							}
						]
					},
					{
						"featureType": "landscape.man_made",
						"elementType": "geometry.stroke",
						"stylers": [
							{
								"color": "#34476b"
							}
						]
					},
					{
						"featureType": "landscape.natural",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#021946"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#283d6a"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "labels.text",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#6f9ba5"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "labels.text.stroke",
						"stylers": [
							{
								"color": "#1d2c4d"
							}
						]
					},
					{
						"featureType": "poi.business",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "poi.park",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#327656"
							}
						]
					},
					{
						"featureType": "poi.park",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#627a66"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#142957"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#98a5be"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "labels.text.stroke",
						"stylers": [
							{
								"color": "#1d2c4d"
							}
						]
					},
					{
						"featureType": "road.arterial",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#223967"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#b0d5ce"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "labels.text.stroke",
						"stylers": [
							{
								"color": "#023e58"
							}
						]
					},
					{
						"featureType": "road.local",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road.local",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "transit",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#98a5be"
							}
						]
					},
					{
						"featureType": "transit",
						"elementType": "labels.text.stroke",
						"stylers": [
							{
								"color": "#1d2c4d"
							}
						]
					},
					{
						"featureType": "transit.line",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#283d6a"
							}
						]
					},
					{
						"featureType": "transit.station",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#3a4762"
							}
						]
					},
					{
						"featureType": "transit.station",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "simplified"
							}
						]
					},
					{
						"featureType": "transit.station",
						"elementType": "labels.icon",
						"stylers": [
							{
								"visibility": "simplified"
							}
						]
					},
					{
						"featureType": "transit.station.bus",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#00aeef"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#4e6d70"
							}
						]
					}
				],
}


const brownsvilleCoords = [
  { lat: 40.676999, lng: -73.922023},
  { lat: 40.6730933, lng: -73.9221088},
  { lat: 40.6685363, lng: -73.9257136},
  { lat: 40.6689269, lng: -73.931121},
  { lat: 40.663224, lng: -73.931465},
  { lat: 40.647238, lng: -73.913741},
  { lat: 40.656518, lng: -73.900394},
  { lat: 40.659285, lng: -73.899579},
  { lat: 40.675694, lng: -73.903238},
  { lat: 40.676999, lng: -73.922023},
]

// brownsvilleArea = new google.maps.Polygon({
//   paths: brownsvilleCoords,
//   strokeColor: '#F9CB77',
//   strokeOpacity: 0.8,
//   strokeWeight: 4,
//   fillColor: '#FF0000',
//   fillOpacity: 0
// });

const bushwickCoords = [
  {lat: 40.700845, lng: -73.941979 },
  {lat: 40.6799282, lng: -73.9058009 },
  {lat: 40.6824016, lng: -73.9029685 },
  {lat: 40.6839637, lng: -73.9052859 },
  {lat: 40.6860464, lng: -73.9054576 },
  {lat: 40.6884545, lng: -73.9045135 },
  {lat: 40.69167, lng: -73.901467 },
  {lat: 40.694306, lng: -73.905973 },
  {lat: 40.6959, lng: -73.904557 },
  {lat: 40.699983, lng: -73.911746 },
  {lat: 40.701057, lng: -73.910673 },
  {lat: 40.702375, lng: -73.912883 },
  {lat: 40.703464, lng: -73.911788 },
  {lat: 40.709369, lng: -73.921766 },
  {lat: 40.705569, lng: -73.9263145 },
  {lat: 40.703936, lng: -73.931293 },
  {lat: 40.700845, lng: -73.941979}
]

// bushwickArea = new google.maps.Polygon({
//   paths: bushwickCoords,
//   strokeColor: '#F9CB77',
//   strokeOpacity: 0.8,
//   strokeWeight: 4,
//   fillColor: '#FF0000',
//   fillOpacity: 0
// });

const canarsieCoords = [
  { lat: 40.659285, lng: -73.899579},
  { lat: 40.656518, lng: -73.900394},
  { lat: 40.647238, lng: -73.913741},
  { lat: 40.646262, lng: -73.912754},
  { lat: 40.64421, lng: -73.913355},
  { lat: 40.6398613, lng: -73.9199321},
  { lat: 40.632291, lng: -73.918762},
  { lat: 40.62164, lng: -73.89473},
  { lat: 40.62835, lng: -73.882155},
  { lat: 40.635613, lng: -73.875246},
  { lat: 40.6535092, lng: -73.8974444},
  { lat: 40.6537045, lng: -73.8983027},
  { lat: 40.659285, lng: -73.899579}
]

// canarsieArea = new google.maps.Polygon({
//   paths: canarsieCoords,
//   strokeColor: '#F9CB77',
//   strokeOpacity: 0.8,
//   strokeWeight: 4,
//   fillColor: '#FF0000',
//   fillOpacity: 0
// });

const cypressHillsCoords = [

  { lat: 40.69167, lng: -73.901467},
  { lat: 40.6884545, lng: -73.9045135},
  { lat: 40.686502, lng: -73.9053718},
  { lat: 40.6839637, lng: -73.9052859},
  { lat: 40.6824016, lng: -73.9029685},
  { lat: 40.6781056, lng: -73.9076892},
  { lat: 40.6761528, lng: -73.9080325},
  { lat: 40.675694, lng: -73.903238},
  { lat: 40.675789, lng: -73.896918},
  { lat: 40.6766053, lng: -73.8901917},
  { lat: 40.6807092, lng: -73.8761893},
  { lat: 40.68028, lng: -73.8747957},
  { lat: 40.6780343, lng: -73.8728003},
  { lat: 40.6736791, lng: -73.8589374},
  { lat: 40.6794725, lng: -73.8615981},
  { lat: 40.6792772, lng: -73.8635722},
  { lat: 40.6824666, lng: -73.8642589},
  { lat: 40.6821412, lng: -73.8660613},
  { lat: 40.691117, lng: -73.868036},
  { lat: 40.690824, lng: -73.87044},
  { lat: 40.682623, lng: -73.888292},
  { lat: 40.684983, lng: -73.890266},
  { lat: 40.68303, lng: -73.893442},
  { lat: 40.684771, lng: -73.894494},
  { lat: 40.682591, lng: -73.896275},
  { lat: 40.687911, lng: -73.900931},
  { lat: 40.688204, lng: -73.900137},
  { lat: 40.69167, lng: -73.901467}
]

// cypressHillsArea = new google.maps.Polygon({
//   paths: cypressHillsCoords,
//   strokeColor: '#F9CB77',
//   strokeOpacity: 0.8,
//   strokeWeight: 4,
//   fillColor: '#FF0000',
//   fillOpacity: 0
// });

const eastBrooklynCoords = [

  { lat: 40.675694, lng: -73.903238 },
  { lat: 40.6537045, lng: -73.8983027 },
  { lat: 40.6535092, lng: -73.8974444 },
  { lat: 40.635844, lng: -73.8751714 },
  { lat: 40.639032, lng: -73.867521 },
  { lat: 40.643526, lng: -73.855548 },
  { lat: 40.651015, lng: -73.856449 },
  { lat: 40.652448, lng: -73.858209 },
  { lat: 40.655248, lng: -73.859539 },
  { lat: 40.65769, lng: -73.862736 },
  { lat: 40.659041, lng: -73.861384 },
  { lat: 40.660083, lng: -73.857651 },
  { lat: 40.663533, lng: -73.858209 },
  { lat: 40.6639562, lng: -73.8544534 },
  { lat: 40.6705322, lng: -73.8563415 },
  { lat: 40.6704509, lng: -73.8572859 },
  { lat: 40.6716716, lng: -73.8575436 },
  { lat: 40.673266, lng: -73.8587241 },
  { lat: 40.6780343, lng: -73.8728003 },
  { lat: 40.68028, lng: -73.8747957 },
  { lat: 40.680313, lng: -73.876576 },
  { lat: 40.6766053, lng: -73.8901917 },
  { lat: 40.675789, lng: -73.896918 },
  { lat: 40.675694, lng: -73.903238 }

]

// eastBrooklynArea = new google.maps.Polygon({
//   paths: eastBrooklynCoords,
//   strokeColor: '#F9CB77',
//   strokeOpacity: 0.8,
//   strokeWeight: 4,
//   fillColor: '#FF0000',
//   fillOpacity: 0
// });

const eastFlatbushCoords = [

  { lat: 40.632291, lng: -73.918762 },
  { lat: 40.640303, lng: -73.919578 },
  { lat: 40.64421, lng: -73.913355 },
  { lat: 40.646262, lng: -73.912754 },
  { lat: 40.663224, lng: -73.931465 },
  { lat: 40.653848, lng: -73.930521 },
  { lat: 40.6524153, lng: -73.9527942 },
  { lat: 40.6360037, lng: -73.9510345 },
  { lat: 40.6315142, lng: -73.945798 },
  { lat: 40.6334032, lng: -73.9205638 },
  { lat: 40.632291, lng: -73.918762 },

]

// eastFlatbushArea = new google.maps.Polygon({
//   paths: eastFlatbushCoords,
//   strokeColor: '#F9CB77',
//   strokeOpacity: 0.8,
//   strokeWeight: 4,
//   fillColor: '#FF0000',
//   fillOpacity: 0
// });

const leffertsGardensCoords = [
  { lat: 40.663029, lng: -73.962665 },
  { lat: 40.66228, lng: -73.963094 },
  { lat: 40.652285, lng: -73.96142 },
  { lat: 40.652936, lng: -73.959317 },
  { lat: 40.652904, lng: -73.957214 },
  { lat: 40.652383, lng: -73.956056 },
  { lat: 40.653848, lng: -73.930521 },
  { lat: 40.663224, lng: -73.931465 },
  { lat: 40.664266, lng: -73.945456 },
  { lat: 40.663322, lng: -73.961034 },
  { lat: 40.663029, lng: -73.962665 }
]

// leffertsGardensArea = new google.maps.Polygon({
//   paths: leffertsGardensCoords,
//   strokeColor: '#F9CB77',
//   strokeOpacity: 0.8,
//   strokeWeight: 4,
//   fillColor: '#FF0000',
//   fillOpacity: 0
// });

export const coordinates = {
	brownsville: brownsvilleCoords,
	bushwick: bushwickCoords,
	canarsie: canarsieCoords,
	cypress_hills: cypressHillsCoords,
	east_brooklyn: eastBrooklynCoords,
	east_flatbush: eastFlatbushCoords,
	lefferts_gardens: leffertsGardensCoords,
}

export const centers = {
	brownsville: { lat: 40.662288, lng: -73.912893},
	bushwick: { lat: 40.695946, lng: -73.916572},
	canarsie: { lat: 40.638643, lng: -73.902016},
	cypress_hills: { lat: 40.683763, lng: -73.881958},
	east_brooklyn: { lat: 40.662677, lng: -73.879854},
	east_flatbush: { lat: 40.646262, lng: -73.931283},
	lefferts_gardens: { lat: 40.659271, lng: -73.946665},
}
