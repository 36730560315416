import React, { useContext } from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import Grid from 'src/components/Grid'
import Modal from 'src/components/Modal'
import Link from 'src/components/Link'
import SanityRichText from 'src/components/SanityRichText'
import { AppContext } from 'src/state/AppState'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { typography, animations, globals, mq, util } from 'src/styles'

const Wrapper = styled(Section)`
`

const IntroWrap = styled.div`
  .h1 {
    margin: 0;
  }
  ${ globals.verticalSpacing('margin-bottom', 0.25) }
`

const ThumbnailInfo = styled.div`
  p {
    transition: color ${ animations.mediumSpeed } ease-in-out;
  }
  p.name {
    margin: 0 0 .2em;
    ${ typography.h4 }
    color: var(--text-color);
    line-height: 1.3em;
  }
  p.title {
    margin: 0;
    ${ typography.bodySmall }
    color: var(--light-text-color);
    line-height: 1.4em;
  }
`

const MemberModal = styled(Modal)`
  width: ${ 100 / 14 * 12 }%;
  padding: 0;
  ${ mq.smallAndBelow } {
    width: 100%;
  }
  ${ util.responsiveStyles('max-width', 1200, 1000, 850, 700) }
  .close-button {
    ${ mq.smallAndBelow } {
      position: sticky;
      display: block;
      top: 0;
      left: 100%;
      margin-bottom: -${ globals.uiElementSizes.small }px;
      background: var(--text-color);
      color: var(--bg-color);
      top: 0;
      right: 0;
      &:hover {
        background: var(--main-color);
        color: var(--bg-color);
      }
    }
  }
`

const HeadshotWrapper = styled.div`
  ${ ({ hasHeadshot }) => hasHeadshot ? `
    ${ mq.mediumAndUp } {
      ${ util.responsiveStyles('padding-top', 60, 40, 30, 16) }
      ${ util.responsiveStyles('padding-left', 60, 40, 30, 16) }
      ${ util.responsiveStyles('padding-bottom', 60, 40, 30, 16) }
    }
  ` : `
    ${ util.responsiveStyles('padding-top', 60, 40, 30, 16) }
    ${ util.responsiveStyles('padding-left', 60, 40, 30, 16) }
    ${ mq.mediumAndUp } {
      ${ util.responsiveStyles('padding-right', 60, 40, 30, 16) }
    }
  ` }
`

const ModalContent = styled.div`
  ${ util.responsiveStyles('padding', 60, 40, 30, 16) }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  ${ ({ hasHeadshot }) => !hasHeadshot ? `
    ${ mq.smallAndBelow } {
      padding-top: 0;
    }
  ` : '' }
`

const ModalHeadshot = styled(Image)`
`

const ModalTitle = styled.div`
  h4 {
    ${ typography.h2 }
    margin: -.15em 0 0;
  }
  p {
    margin: 0 0 1.5em;
    ${ typography.bodyMedium }
    color: var(--light-text-color);
  }
`

const MemberLinks = styled.ul`
  list-style: none;
  ${ typography.bodySmall }
  margin-top: 1.75em;
  padding: 0;
  li {
    margin: 0;
  }
  a {
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    color: var(--main-color);
    position: relative;
    line-height: 1.7em;
    font-weight: ${ typography.bold };
    svg {
      margin-right: -5px;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 85%;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--text-color);
      transform: scaleX(0);
      transform-origin: right center;
      transition: transform ${ animations.mediumSpeed } ease-in-out;
    }
    &:hover {
      &:after {
        transform-origin: left center;
        transform: scaleX(1);
      }
    }
  }
`

const MemberBio = styled(SanityRichText)`
  p {
    ${ typography.body }
  }
`

const ThumbnailImage = styled(Image)`
  margin-bottom: .75em;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    opacity: 0;
    transition: opacity ${ animations.mediumSpeed } ease-in-out;
  }
`

const ThumbnailItem = styled.a`
  display: block;
  ${ ({ clickable }) => clickable ? `
    &:hover {
      ${ ThumbnailInfo } {
        p.name {
          color: var(--main-color);
        }
        p.title {
          color: var(--text-color);
        }
      }
      ${ ThumbnailImage } {
        &:after {
          opacity: .2;
        }
      }
    }
  ` : `
    cursor: default;
  ` }
`

const TeamMember = ({ member }) => {
  const { toggleModal } = useContext(AppContext)
  let onClick = () => { return false }
  if (member?._rawBio) {
    // onClick = () => toggleModal(member._key + '-team-modal')
    onClick = () => {
      toggleModal(member._key + '-team-modal')
    }
  }
  return (
    <ThumbnailItem onClick={onClick} clickable={member?._rawBio}>
      {member?.headshot?.asset?.gatsbyImageData && (
        <ThumbnailImage rounded image={member.headshot.asset.gatsbyImageData}/>
      )}
      <ThumbnailInfo>
        <p className='name'>{member.name}</p>
        <p className='title'>{member.jobTitle}</p>
      </ThumbnailInfo>
    </ThumbnailItem>
  )
}

const TeamGrid = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  isFirstSection,
  headline,
  teamMembers,
  id
}) => {
  return (
    <>
      <Wrapper
        className={className}
        prevTheme={prevTheme}
        setTheme={theme}
        nextTheme={nextTheme}
        isFirstSection={isFirstSection}
        id={id}
      >
        <Grid small='m [1] m' medium='1 [12] 1'>
          <IntroWrap>
            <h3 className='h1'>{headline || 'Our Team'}</h3>
            <hr className='short'/>
          </IntroWrap>
        </Grid>
        <Grid small='m [1] m' medium='1 [12] 1'>
          <div>
            {teamMembers?.length > 0 && (
              <Grid
                small='[1] [1]'
                medium='[1] [1] [1]'
                large='[1] [1] [1] [1] [1]'
                larger='[1] [1] [1] [1] [1] [1]'
                colGap={['20px', '26px', '30px']}
                rowGap={['36px', '50px', '60px']}
                vAlign='top'
              >
                {teamMembers.map(member => (
                  <TeamMember member={member} key={member._key} />
                ))}
              </Grid>
            )}
          </div>
        </Grid>
      </Wrapper>

      {teamMembers.map(member => (
        <MemberModal
          id={member._key + '-team-modal'}
          overlayClassName='team-member-modal'
        >
          <Grid
            small='[1]'
            medium='[3] [5]'
            extraLarge='[4] [5]'
            vAlign='top'
            rowGap={member?.headshot ? ['10px', '26px', '30px'] : 0}
          >
            <HeadshotWrapper hasHeadshot={member?.headshot?.asset?.gatsbyImageData}>
              {member?.headshot?.asset?.gatsbyImageData ? (
                <ModalHeadshot image={member.headshot.asset.gatsbyImageData} />
              ) : (
                <ModalTitle>
                  <h4>{member.name}</h4>
                  <p>{member.jobTitle}</p>
                </ModalTitle>
              )}
            </HeadshotWrapper>
            <ModalContent hasHeadshot={member?.headshot?.asset?.gatsbyImageData}>
              <div>
                {member?.headshot?.asset?.gatsbyImageData && (
                  <ModalTitle>
                    <h4>{member.name}</h4>
                    <p>{member.jobTitle}</p>
                  </ModalTitle>
                )}
                <MemberBio text={member._rawBio} />
              </div>
              {member?.links?.length > 0 && (
                <MemberLinks>
                  {member.links.map(link => {
                    let linkUrl = link.url
                    if (link.icon === 'email') {
                      linkUrl = link.email
                    }
                    return (
                      <li>
                        <Link to={linkUrl} external target='_blank'>
                          {link.icon}
                          <MdKeyboardArrowRight size={18} />
                        </Link>
                      </li>
                    )
                  })}
                </MemberLinks>
              )}
            </ModalContent>
          </Grid>
        </MemberModal>
      ))}
    </>
  )
}

export default TeamGrid
