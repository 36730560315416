import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO ({
		lang = 'en',
		pagePath,
		title,
		description = '',
		keywords = [],
		shareImage,
		pageMeta,
		seoTitle,
		ogTitle,
		ogImage,
		ogDescription,
		twitterDescription,
		twitterImage,
		twitterTitle
	}) {
	const { site, appleTouchIcon, favicon, socialShareImage, allSanitySiteSettings } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
					}
				}
				allSanitySiteSettings {
			    edges {
			      node {
			      	title
			        favicon {
			          asset {
			            url
			          }
			        }
			        touchicon {
			          asset {
			            url
			          }
			        }
			        seo {
			        	metaDescription
			        	keywords
			        }
			      }
			    }
			  }
				favicon: file(relativePath:{eq: "images/favicon.png"}) {
					publicURL
				}
				appleTouchIcon: file(relativePath: { eq: "images/touch-icon.png" }) {
					publicURL
				}
				socialShareImage: file(relativePath: { eq: "images/share-image.png" }) {
					publicURL
					absolutePath
				}
			}
		`
	)

	const sanitySiteSettings = allSanitySiteSettings?.edges[0]?.node

	///
	const metaTitle = title
	const metaDescription = description || sanitySiteSettings?.description
	let metaKeywords = keywords || sanitySiteSettings?.seo?.keywords
	///

	const host = process.env.GATSBY_SITE_URL

	// let metaShareImage = host + socialShareImage.publicURL
	// if (ogImage || twitterImage) {
	// 	metaShareImage = ogImage || twitterImage
	// }

	if (metaKeywords.length > 0) {
		metaKeywords = metaKeywords.join(', ')
	}

	// Default SEO content from file structure
	const localTouchIcon = host + appleTouchIcon.publicURL
	const localFavicon = host + favicon.publicURL
	const localShareImage = host + socialShareImage.publicURL
	// Sanity SEO content
	const sanityFavicon = sanitySiteSettings?.favicon?.asset?.url
	const sanityTouchIcon = sanitySiteSettings?.touchicon?.asset?.url
	const siteTitle = sanitySiteSettings?.title

	const metaFavicon = sanityFavicon || localFavicon
	const metaTouchIcon = sanityTouchIcon || localTouchIcon
	const metaShareImage = shareImage || localShareImage

	let titleTemplate = pagePath !== 'home' && metaTitle ? `${ metaTitle } | ${ siteTitle || site.siteMetadata.title }` : `${ siteTitle || site.siteMetadata.title }`
	if (seoTitle) {
		titleTemplate = seoTitle
	}
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={pageMeta?.metaTitle || title || site.siteMetadata.title}
			titleTemplate={pageMeta?.metaTitle || titleTemplate}
			meta={[
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0',
				},
				{
					name: 'facebook-domain-verification',
					content: '9890ei8xdl99tonwt84x8mjl0a8kim',
				},
				{
					name: 'description',
					content: metaDescription,
				},
				{
					property: 'og:title',
					content: pageMeta?.metaTitle || titleTemplate,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: metaShareImage
				},
				{
					property: 'og:description',
					content: metaDescription,
				},
				{
					name: 'twitter:image',
					content: metaShareImage
				},
				{
					name: 'twitter:card',
					content: 'summary_large_image',
				},
				{
					name: 'twitter:creator',
					content: site.siteMetadata.author,
				},
				{
					name: 'twitter:title',
					content: pageMeta?.metaTitle || titleTemplate,
				},
				{
					name: 'twitter:description',
					content: metaDescription,
				},
				{
					name: 'keywords',
					content: metaKeywords
				}
			]}
			link={[
				{ rel: 'icon', type: 'image/png', sizes: '32x32', href: metaFavicon },
				{ rel: 'apple-touch-icon', type: 'image/png', sizes: '120x120', href: metaTouchIcon }
			]}
		/>
	)
}

export default SEO
