import React, { useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextSection from 'src/components/TextSection'
import Grid from 'src/components/Grid'
import PostCard from 'src/components/PostCard'
import Select from 'src/components/Select'
import Button from 'src/components/Button'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { mq, globals } from 'src/styles'
import { slugify } from 'src/utils/format'

const Wrapper = styled(Section)`
  position: relative;
  ${ mq.largeAndBelow } {
    padding-top: ${ 100 / 14 }vw;
    padding-bottom: ${ 100 / 14 }vw;
  }
  ${ mq.smallAndBelow } {
    padding-top: var(--site-margins);
  }
`

const PostItem = styled.div`
  height: 100%;
  .post-card {
    height: 100%;
    ${ mq.smallAndBelow } {
      min-height: 50vw;
    }
  }
`

const PostGrid = styled(Grid)`
  width: 100%;
`

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  ${ globals.verticalSpacing('margin-top') }
  ${ ({ postCount }) => `
    ${ mq.largeAndUp } {
      ${ postCount < 2 ? 'display: none;' : '' }
    }
  ` }
  > div:first-of-type {
    margin-right: 10px;
  }
`

const ArticleGrid = ({
  className,
  nextTheme,
  prevTheme,
  theme = 'default',
  isFirstSection = false,
  posts,
  title,
  description,
  currentSlug,
  gridLarger,
  topHeadline,
  topText,
  id,
  pressPage
}) => {
  const { categories } = useStaticQuery(
    graphql`
      query {
        categories: allSanityCategory {
          edges {
            node {
              title
            }
          }
        }
      }
    `
  )

  const categoryFilters = categories?.edges.map(cat => { return { label: cat.node.title, value: slugify(cat.node.title) } })

  categoryFilters.unshift({ label: 'All Posts', value: '/blog' })

  const handleFilter = event => {
    if (event.target.value !== '/blog') {
      navigate('/blog/categories/' + event.target.value)
    } else {
      navigate('/blog')
    }
  }

  const [visiblePosts, setVisiblePosts] = useState(12)

  const postList = posts.slice(0, visiblePosts)

  const loadMorePosts = () => {
    if (posts.length >= visiblePosts) {
      setVisiblePosts(visiblePosts + 12)
    }
  }

  return (
    <>
      <TextSection
        theme={!pressPage ? 'navy' : 'default'}
        nextTheme='default'
        headline={topHeadline}
        headlineElement='h1'
        headlineSize='h1'
        text={topText}
        isFirstSection
        alignment='left'
        actions={[
          <div>
            <Select
              setTheme={pressPage ? 'default' : 'light'}
              onChange={handleFilter}
              name='categoryFilter'
              value={currentSlug}
              // label='Request Type'
              defaultOption='Filter Articles'
              options={categoryFilters}
            />
          </div>
        ]}
      />
      <Wrapper
        className={className}
        setTheme={theme}
        prevTheme={!pressPage ? 'navy' : 'default'}
        nextTheme={nextTheme}
        id={id}
      >
        <Grid
          small='container'
          rowGap={['20px', '26px', '30px']}
          as={ScrollEntrance}
          delay={2}
        >
          <PostGrid
            small='[1]'
            medium='[1] [1]'
            large='[1] [1] [1]'
            larger={gridLarger || '[1] [1] [1] [1]'}
            colGap={['0', '26px', '30px']}
            rowGap={['40px', '60px', '80px']}
          >
            {postList.map((post, index) => (
              <PostItem
                key={post._key}
                firstSlide={index === 0}
              >
                <PostCard post={post?.node || post} />
              </PostItem>
            ))}
          </PostGrid>
        </Grid>

        {posts.length > visiblePosts && (
          <Pagination postCount={posts?.length}>
            <div>
              <Button
                size='large'
                title='Load more posts'
                setTheme='lightGrey'
                icon='keyboard_arrow_down'
                iconPosition='right'
                onClick={loadMorePosts}
              >Load More</Button>
            </div>
          </Pagination>
        )}
      </Wrapper>
    </>
  )
}

export default ArticleGrid
