import React from 'react'
import { graphql } from 'gatsby'
import SEO from 'src/components/SEO'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import ComponentRenderer from 'src/components/ComponentRenderer'
import TextSection from 'src/components/TextSection'
import TextLink from 'src/components/TextLink'
import Button from 'src/components/Button'
import SchoolList from 'src/components/SchoolList'
import SchoolIntro from 'src/components/SchoolIntro'
import Splitter from 'src/components/Splitter'
import { getBackupShareImage } from 'src/utils/getBackupShareImage'
import { slugify } from 'src/utils/format'

const LowerSchool = ({ data }) => {
  const page = data?.school?.school
  const pageMeta = data?.school?.school?.meta
  let path = slugify(page?.title)
  if (page?.slug?.current) {
    path = page.slug.current
  }
  let modules = page?.modules || []
    // Filter out hidden modules
    modules = modules?.filter(module => !module?.hidden)
  const shareImage = page.thumbnail.asset.url

  return (
    <>
      <SEO
        pagePath={path}
        title={page?.title}
        pageMeta={pageMeta}
        seoTitle={pageMeta?.metaTitle}
        description={pageMeta?.metaDescription}
        keywords={pageMeta?.keywords}
        shareImage={pageMeta?.shareImage?.asset?.url || shareImage}
      />
      <Header
        location={path}
      />

      <SchoolIntro page={page} />

      {modules?.map((item, index) => {
        const prevSection = modules[index - 1]
        const nextSection = modules[index + 1]
        let prevTheme = false
        let nextTheme = false
        if (prevSection && prevSection.width !== 'fullWidth') {
          prevTheme = prevSection.theme || 'default'
        }
        if (nextSection && nextSection.width !== 'fullWidth') {
          nextTheme = nextSection.theme || 'default'
        }
        if (!item.theme && item.width !== 'fullWidth') {
          item.theme = 'default'
        }
        return (
          <ComponentRenderer
            item={item} key={item?._key || 'section-' + index}
            nextTheme={index === modules.length - 1 ? 'navy' : nextTheme}
            prevTheme={prevTheme}
            isFirstSection={false}
            isLastSection={false}
          />
        )
      })}

      {/* <TextSection
        headline='Ascend lower schools'
        text='Find out more about our lower school program, approach, and values.'
        textSize='bodyMedium'
        theme='navy'
        actions={[
          <Button to='schools/lower-schools' title='Learn more about lower schools'>Learn more</Button>
        ]}
      /> */}
      
      <SchoolList
        listType='lower'
        prevTheme='navy'
        theme='default'
        nextTheme='lightGrey'
        excludedSchool={data?.school}
      />
      <Splitter
        prevTheme='default'
        theme='lightGrey'
        teachersHeadline='Teach here'
        layout='columns'
      />
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    school: sanityLowerSchool(id: { eq: $id }) {
      _key
      _id
      school {
        ...School
      }
    }
  }
`

export default LowerSchool
