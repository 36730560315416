import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'
import Map from 'src/components/Map'
import NeighborhoodMap from 'src/components/NeighborhoodMap'
import ScrollEntrance from 'src/components/ScrollEntrance'
import MaterialIcon from 'src/components/MaterialIcon'
import { MapPin } from 'src/components/Marker'
import { globals, colors, typography, animations, mq, util } from 'src/styles'

const Wrapper = styled(Section)``

const MapWrapper = styled.div`
	background: ${ colors.lightGrey };
	position: relative;
	${ ({ setRatio }) => setRatio ? `
		padding-bottom: ${ 3 / 4 * 100 }%;
	` : '' }
	.map-wrapper {
		position: absolute;
		top: 0;
		left: 0;
	}
	svg {
		display: block;
	}
	#finder-google-map {
		position: absolute;
	}
`

const ArrowIcon = styled(MaterialIcon)`
	font-size: 24px;
	position: absolute;
	top: -3px;
	right: -8px;
	color: ${ colors.mainColor };
	opacity: 0;
	transform: translateX(-5px);
	transition: opacity ${ animations.mediumSpeed } ease-in-out,
							transform ${ animations.mediumSpeed } ease-in-out;
`

const LocationItem = styled.button`
	appearance: none;
	border: none;
	padding: none;
	border-radius: none;
	outline: none;
	margin: 0;
	background: transparent;
	height: auto;
	display: flex;
	color: inherit;
	width: 100%;
	flex-grow: 1;
	text-align: inherit;
	align-items: center;
	padding: .3em 0;
	cursor: pointer;
	position: relative;
	svg {
		flex-shrink: 0;
		flex-grow: 0;
		margin-right: 6px;
		margin-left: -2px;
		display: block;
		margin-top: -4px;
	}
	p {
		transition: color ${ animations.mediumSpeed } ease-in-out;
		padding-right: 1em;
		${ typography.bodySmall }
		font-weight: ${ typography.medium };
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		margin: 0;
	}
	&:hover {
		${ mq.largeAndUp } {
			${ ArrowIcon } {
				opacity: 1;
				transform: none;
			}
		}
	}
`

const LocationTitle = styled.p`
	display: block;
	margin: 0;
	${ typography.body }
	font-weight: ${ typography.medium };
	line-height: 1em;
	padding-right: 30px;
`

const SchoolListPanel = styled.div`
	direction: ltr;
	background: ${ colors.lightGrey };
	height: 100%;
	max-height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	padding: var(--site-margins);
	text-align: left;
	${ mq.mediumAndBelow } {
		height: 40vh;
		margin-top: -2px;
	}
	${ mq.largerAndUp } {
		padding: 30px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	> h3 {
		margin: -.1em 0 .65em 0;
	}
	> div {
		${ mq.largeAndUp } {
			columns: 2;
		}
		${ mq.largerAndUp } {
			columns: 1;
		}
	}
	ul {
		break-inside: avoid;
		padding: 0;
		margin: 0;
		list-style: none;
		h6 {
			margin: 0 0 .75em;
			${ typography.h6 }
			${ util.responsiveStyles('font-size', 13, 12, 12, 12) }
			text-transform: uppercase;
			letter-spacing: .1em;
			color: var(--light-text-color);
		}
		&:not(.first-list) {
			h6 {
				margin: 2.5em 0 .75em;
			}
		}
	}
`

const SchoolsMap = ({
	className,
	theme,
	prevTheme,
	nextTheme,
	isFirstSection,
	title,
	id
}) => {
	const { allSanityLowerSchool, allSanityMiddleSchool, allSanityUpperSchool } = useStaticQuery(
		graphql`
			query {
				allSanityLowerSchool {
					edges {
						node {
							_type
							_id
							school {
								...School
							}
						}
					}
				}
				allSanityMiddleSchool {
					edges {
						node {
							_type
							_id
							school {
								...School
							}
						}
					}
				}
				allSanityUpperSchool {
					edges {
						node {
							_type
							_id
							school {
								...School
							}
						}
					}
				}
			}
		`
	)

	const locations = allSanityLowerSchool?.edges.concat(allSanityMiddleSchool?.edges, allSanityUpperSchool?.edges)
	const locationsArray = []

	const [neighborhood, setNeighborhood] = useState(null)
	const [currentSchool, setCurrentSchool] = useState(null)

	locations.forEach((item, index) => {
		const location = item?.node?.school?.mapLocation
		const schoolType = item?.node?._type || 'lowerSchool'
		if (location && location._key && location.lat && location.lng) {
			locationsArray.push({
				_key: item?.node?._id,
				lat: location.lat,
				lng: location.lng,
				type: schoolType
			})
		}
	})

	const locationByNeighborhood = hood => {
		return locations.filter(location => location?.node?.school?.stats?.mapDistrict === hood)
	}

	const neighborhoods = [
		{ title: 'Brownsville', value: 'brownsville' },
		{ title: 'Bushwick', value: 'bushwick' },
		{ title: 'Canarsie', value: 'canarsie' },
		{ title: 'Cypress Hills', value: 'cypressHills' },
		{ title: 'East Brooklyn', value: 'eastBrooklyn' },
		{ title: 'East Flatbush', value: 'eastFlatbush' },
		{ title: 'Flatbush', value: 'leffertsGardens' }
	]

	return (
		<Wrapper
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			setTheme={theme}
			isFirstSection={isFirstSection}
			id={id}
		>
			<Grid small='container' extraLarge='2 [10] 2'>
				<Grid
					small="[1]"
					large="[8] [4]"
					vAlign="top"
					gridDirection='rtl'
					as={ScrollEntrance}
					delay={1}
				>
					<div>
						<MapWrapper setRatio={neighborhood || currentSchool}>
							{neighborhood || currentSchool ? (
								<Map neighborhood={neighborhood} locations={locations} currentSchool={currentSchool} />
							) : (
								<NeighborhoodMap setNeighborhood={setNeighborhood} />
							)}
						</MapWrapper>
					</div>
					<div style={{ height: '100%', position: 'relative' }}>
						<SchoolListPanel>
							<h3 className='h2'>{title || 'Our Schools'}</h3>
							<div>
								{neighborhoods.map((neighborhood, index) => (
									<ul className={index === 0 ? 'first-list' : ''}>
										<h6>{neighborhood.title}</h6>
										{locationByNeighborhood(neighborhood?.value).map((item, index) => {
											const locationType = item?.node?._type
											const location = item?.node?.school
											//if (!location || !location._key || !location.lat || !location.lng) {
											if (!location) {
												return false
											}
											return (
												<div>
													<LocationItem
														key={location._key}
														target="_blank"
														to={location.website}
														external
														onClick={() => setCurrentSchool(item?.node)}
													>
														<MapPin type={locationType} />
														<div style={{ position: 'relative', zIndex: 3 }}>
															<LocationTitle>{location.title}</LocationTitle>
															<ArrowIcon>keyboard_arrow_right</ArrowIcon>
														</div>
													</LocationItem>
												</div>
											)
										})}
									</ul>
								))}
							</div>
						</SchoolListPanel>
					</div>
				</Grid>
			</Grid>
		</Wrapper>
	)
}

export default SchoolsMap
