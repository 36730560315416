import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import Image from 'src/components/Image'
import { colors, animations, mq, util, globals, typography } from 'src/styles'
import Modal from 'src/components/Modal'
import { AppContext } from 'src/state/AppState'

const Wrapper = styled(Section)``

const CurriculumItem = styled.div`
	text-align: center;
	.h3 {
		margin-bottom: 0;
	}
	p {
		margin: 0 0 1em 0;
		color: var(--light-text-color);
	}
	.gatsby-image-wrapper {
		&:after {
			background: ${ colors.textColor };
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			content: '';
			display: block;
			opacity: 0;
			transition: opacity ${ animations.mediumSpeed } ease-in-out;
		}
	}
	> a {
		display: block;
		&:hover {
			.text-link {
				&:after {
					transform-origin: left center;
					transform: scaleX(1);
				}
			}
			.gatsby-image-wrapper:after {
				opacity: .2;
			}
		}
	}
`

const CurriculumModal = styled(Modal)`
	width: ${ 100 / 14 * 12 }%;
	padding: 0;
	${ mq.mediumAndBelow } {
		width: 100%;
	}
	${ util.responsiveStyles('max-width', 1200, 1000, 850, 700) }
	.close-button {
		${ mq.smallAndBelow } {
			position: sticky;
			display: block;
			top: 0;
			left: 100%;
			margin-bottom: -${ globals.uiElementSizes.small }px;
			background: var(--text-color);
			color: var(--bg-color);
			top: 0;
			right: 0;
			&:hover {
				background: var(--main-color);
				color: var(--bg-color);
			}
		}
	}
`

const ModalContent = styled.div`
	${ util.responsiveStyles('padding', 60, 60, 50, 24) }
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
`

const ModalTitle = styled.div`
	h4 {
		${ typography.h3 }
		margin: -.15em 0 0;
	}
	p {
		margin: 0;
		${ typography.bodyMedium }
		color: var(--light-text-color);
	}
`

const PageImage = styled.div`
	box-shadow: 0 10px 25px ${ rgba(colors.textColor, 0.2) };
	${ mq.largerAndUp } {
		${ util.responsiveStyles('margin-left', 60, 40, 30, 16) }
	}
`

const ModalThumbnail = styled.div`
	${ mq.largeAndBelow } {
		display: none;
	}
`

const Curriculum = ({
	className,
	theme,
	prevTheme,
	nextTheme,
	isFirstSection,
	headline,
	items,
	id
}) => {
	const { toggleModal } = useContext(AppContext)
	return (
		<>
			<Wrapper
				className={className}
				setTheme={theme}
				prevTheme={prevTheme}
				nextTheme={nextTheme}
				isFirstSection={isFirstSection}
				id={id}
			>
				<Grid small='m [1] m'>
					{headline && (
						<div style={{ marginBottom: 'calc(var(--site-margins) / 2)' }}>
							<h3 className='h1'>{headline}</h3>
						</div>
					)}
					<div>
						<Grid
							small='[1]'
							medium='[1] [1] [1]'
							colGap='var(--site-gutters)'
							rowGap='var(--site-margins)'
							vAlign='top'
						>
							{items.map((item, index) => (
								<CurriculumItem key={item._key}>
									<a onClick={() => toggleModal(item._key + '-curriculum-modal')}>
										{item?.image?.asset?.gatsbyImageData && (
											<Image image={item.image.asset.gatsbyImageData} sizes='1000px' />
										)}
										<h4 className='h3' style={{ marginTop: '.65em' }}>{item.title}</h4>
										<p>{item.grade}{item.subject && ', ' + item.subject}</p>
										<TextLink theme='mainColor' as='span'>View lesson plan</TextLink>
									</a>
								</CurriculumItem>
							))}
						</Grid>
					</div>
				</Grid>
			</Wrapper>

			{items.map(item => (
				<CurriculumModal
					id={item._key + '-curriculum-modal'}
					overlayClassName='team-member-modal'
				>
					<ModalContent hasHeadshot={item?.image}>
						<Grid
							small='[1]'
							large='[1] [2]'
							vAlign='top'
							rowGap={item?.image ? ['10px', '26px', '30px'] : 0}
						>
							<div>
								<Grid small='[1]' rowGap='20px'>
								<ModalTitle>
									<h4>{item.title}</h4>
									<p>{item.grade}{item.subject && ', ' + item.subject}</p>
								</ModalTitle>
								{item?.image?.asset?.gatsbyImageData && (
									<ModalThumbnail><Image image={item.image.asset.gatsbyImageData} /></ModalThumbnail>
								)}
								{item?.pdf?.asset?.url && (
									<div css={css`
										${ mq.largeAndBelow } {
											display: none;
										}
									`}>
										<Button
											shape='block'
											to={item.pdf.asset.url}
											external
											target='_blank'
											setTheme='lightGrey'
										>View PDF</Button>
									</div>
								)}
								</Grid>
							</div>
							<div>
								{item?.pageImage?.asset?.gatsbyImageData && (
									<PageImage><Image image={item.pageImage.asset.gatsbyImageData} /></PageImage>
								)}
								{item?.pdf?.asset?.url && (
									<div css={css`
										margin-top: 30px;
										${ mq.largerAndUp } {
											display: none;
										}
									`}>
										<Button
											shape='block'
											to={item.pdf.asset.url}
											external
											target='_blank'
											setTheme='textColor'
										>View PDF</Button>
									</div>
								)}
							</div>
						</Grid>
					</ModalContent>
				</CurriculumModal>
			))}
		</>
	)
}

export default Curriculum
